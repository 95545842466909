import { useState } from "react";
import "../style/layer-control.css";

import layerIcon from "../assets/images/layers.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LayerControl({ layers, onLayerChange }) {
    const [layersOpen, setLayersOpen] = useState(false);
    const [openedSettings, setOpenedSettings] = useState([]);

    const handleOpacityChange = (e, layer) => {
        onLayerChange(layer.id, parseFloat(e.target.value));
    };

    const openSettings = (layerId) => {
        if (openedSettings.includes(layerId)) {
            setOpenedSettings(openedSettings.filter((id) => id !== layerId));
        } else {
            setOpenedSettings([...openedSettings, layerId]);
        }
    };

    return (
        <>
            <div
                className={"layers-icon-container " + (layersOpen && "active")}
                onClick={() => setLayersOpen(!layersOpen)}
            >
                <img src={layerIcon} alt="Calques" />
            </div>
            {layersOpen && (
                <div className="layers-container">
                    <h3>Calques</h3>
                    <ul>
                        {layers.map((layer) => (
                            <>
                                <li key={layer.id}>
                                    <div style={{ display: "flex" }}>
                                        {layer.available ? (
                                            layer.empty ? (
                                                <div className="empty-layer">
                                                    <FontAwesomeIcon
                                                        icon="fa-solid fa-triangle-exclamation"
                                                        title="Aucune donnée trouvée pour cette commune."
                                                    />
                                                </div>
                                            ) : (
                                                <input
                                                    type="checkbox"
                                                    checked={layer.visible}
                                                    onChange={() =>
                                                        onLayerChange(
                                                            layer.id,
                                                            layer.opacity
                                                        )
                                                    }
                                                />
                                            )
                                        ) : layer.loading ? (
                                            <div
                                                className="loading-layer"
                                                onClick={() =>
                                                    onLayerChange(
                                                        layer.id,
                                                        layer.opacity
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon="fa-solid fa-spinner"
                                                    spin
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {layer.empty ? (
                                                    <div className="empty-layer">
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-triangle-exclamation"
                                                            title="Aucune donnée trouvée pour cette commune."
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="dl-layer"
                                                        onClick={() =>
                                                            onLayerChange(
                                                                layer.id,
                                                                layer.opacity
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon="fa-solid fa-download" />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <span
                                            className="layer-control-label"
                                            onClick={() => {
                                                onLayerChange(
                                                    layer.id,
                                                    layer.opacity
                                                );
                                            }}
                                        >
                                            {layer.name}
                                        </span>
                                    </div>
                                    {layer.opacity !== undefined && (
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                openSettings(layer.id)
                                            }
                                            icon="fa-solid fa-gear"
                                            className="layers-settings-icon"
                                        />
                                    )}
                                </li>
                                {layer.opacity !== undefined &&
                                    openedSettings.includes(layer.id) && (
                                        <li key={layer.id + "-settings"}>
                                            <label
                                                htmlFor={layer.id + "-opacity"}
                                            >
                                                Opacité ({layer.opacity})
                                            </label>
                                            <input
                                                className="layer-settings"
                                                type="range"
                                                name={layer.id + "-opacity"}
                                                id={layer.id + "-opacity"}
                                                min={0}
                                                max={0.5}
                                                step={0.01}
                                                value={layer.opacity}
                                                onChange={(e) =>
                                                    handleOpacityChange(
                                                        e,
                                                        layer
                                                    )
                                                }
                                            />
                                        </li>
                                    )}
                            </>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default LayerControl;
