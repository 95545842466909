function HoverInfo({ data }) {
    if (data != null) {
        if (data.distance !== undefined) {
            return (
                <div
                    className="hover-info-container"
                    style={{ top: data.point.y + 32, left: data.point.x }}
                >
                    <div>
                        <span className="label">Distance</span>
                        <span className="value">
                            {data.distance.toFixed(2)} m
                        </span>
                    </div>
                    <div>
                        <span className="label">Aire</span>
                        <span className="value">{data.area.toFixed(2)} m²</span>
                    </div>
                </div>
            );
        } else if (data.indoor !== undefined) {
            return (
                <div
                    className="hover-info-container"
                    style={{ top: data.point.y + 32, left: data.point.x }}
                >
                    <div>
                        <span className="label">Nom</span>
                        <span className="value">{data.name}</span>
                    </div>
                    <div>
                        <span className="label">Intérieur</span>
                        <span className="value">{data.indoor}</span>
                    </div>
                    <div>
                        <span className="label">Extérieur</span>
                        <span className="value">{data.outdoor}</span>
                    </div>
                    <div>
                        <span className="label">Total</span>
                        <span className="value">{data.total}</span>
                    </div>
                    <div>
                        <span className="label">Source</span>
                        <span className="value">{data.source}</span>
                    </div>
                    <div>
                        <span className="label">
                            {data.existing === true ? "Existant" : "Projet"}
                        </span>
                    </div>
                    {/* <div>
                        <span className="label">Type de structure</span>
                        <span className="value">{data.structure}</span>
                    </div>
                    <div>
                        <span className="label">Catégorie</span>
                        <span className="value">{data.visibility}</span>
                    </div> */}
                </div>
            );
        } else {
            return (
                <div
                    className="hover-info-container"
                    style={{ top: data.point.y + 32, left: data.point.x }}
                >
                    <div>
                        <span className="label">
                            {data.libelle ? "Zone" : "Parcelle"}
                        </span>
                        <span className="value">{data.name}</span>
                    </div>
                    <div>
                        <span className="label">
                            {data.libelle ? data.libelle : "Superficie"}
                        </span>
                        {data.libelle === undefined && (
                            <span className="value">
                                {data.contenance
                                    ? data.contenance
                                    : "Superficie inconnue."}{" "}
                                m²
                            </span>
                        )}
                    </div>
                    {data.zonePEB && (
                        <div>
                            <span className="label">Zones PEB</span>
                            <span className="value">{data.zonePEB}</span>
                        </div>
                    )}
                    {data.plu && (
                        <>
                            <div>
                                <span className="label">Zone</span>
                                <span className="value">{data.plu.zone}</span>
                            </div>
                            <div>
                                <span className="label">
                                    {data.plu.libelle}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }
}

export default HoverInfo;
