import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

import { useGlobalState } from "../pages/app-container";
import CadastreServices from "../services/cadastre";

import catchmentAreaIcon from "../assets/images/catchment-area.png";
import "../style/catchment-area.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./loader";

const requestIsochrone = async (
    lng,
    lat,
    travelTime,
    travelDistance,
    requestMode,
    nbTerrains
) => {
    let response;

    if (requestMode === "temps" || requestMode === "distance") {
        switch (requestMode) {
            case "temps":
                if (nbTerrains > 0) {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_minutes=${travelTime},${
                            travelTime * 2
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                } else {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_minutes=${travelTime}&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                }
                break;
            case "distance":
                if (nbTerrains > 0) {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_meters=${
                            travelDistance * 1000
                        },${
                            travelDistance * 1000 * 2
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                } else {
                    response = await fetch(
                        `https://api.mapbox.com/isochrone/v1/mapbox/driving/${lng},${lat}?contours_meters=${
                            travelDistance * 1000
                        }&polygons=true&access_token=pk.eyJ1Ijoicm11bnRkcyIsImEiOiJjbGRucGtxZTgwazBpM3BxZzdpOXBsdTlkIn0.Ixv9Rh6eRAgrN0hkYWi8KA`
                    );
                }
                break;
            default:
                break;
        }

        const data = await response.json();

        if (data.features.length === 2) {
            data.features[0].properties.fillColor = "#ccad00";
            data.features[1].properties.fillColor = "#0088cc";
        } else if (data.features.length === 1) {
            data.features[0].properties.fillColor = "#0088cc";
        }

        return data;
    } else {
        return;
    }
};

const requestPopulationForCity = async (commune) => {
    const population = await fetch(
        `https://geo.api.gouv.fr/communes?code=${commune.properties.id}`
    );
    const data = await population.json();
    return data?.[0].population;
};

const getCitiesInNearDepartments = async (marker) => {
    const dpt = CadastreServices.getDepartementDataByCoords(
        marker._lngLat.lng,
        marker._lngLat.lat
    );

    let cities = [];

    if (dpt) {
        const adjacentsDepartments =
            CadastreServices.getAdjacentDepartementsByDepartement(dpt);
        const nearDepartments = CadastreServices.getNearAdjacentDepartements(
            marker,
            adjacentsDepartments
        );

        for (const department of nearDepartments) {
            const result = await CadastreServices.getCommunesByDepartement(
                department.properties.code
            );
            if (result !== null) {
                cities.push(result);
            }
        }
    }

    return cities;
};

const calculateWeightedPopulation = (
    marker,
    userTerrains,
    cities,
    clubsInZone,
    distanceImpactFactor = 1
) => {
    const userPoint = {
        type: "Feature",
        geometry: {
            coordinates: [marker.lng, marker.lat],
            type: "Point",
        },
        properties: {},
    };

    let results = {
        totalPopulationUser: 0,
        cities: [],
    };

    // 3. Calculer l'impact pour chaque ville
    cities.forEach((city) => {
        const cityLocation = turf.centroid(city.commune.geometry);

        const distanceUser = turf.distance(cityLocation.geometry, userPoint, {
            units: "kilometers",
        });

        const impactUser =
            userTerrains /
            (Math.pow(distanceUser, 2 * distanceImpactFactor) + 1e-6);

        let totalImpact = impactUser;

        // Ajouter l'impact des clubs concurrents
        clubsInZone?.[0].features.forEach((club) => {
            const distanceClub = turf.distance(cityLocation, club.geometry, {
                units: "kilometers",
            });

            const impactClub =
                club.properties.indoor /
                (Math.pow(distanceClub, 2 * distanceImpactFactor) + 1e-6);

            totalImpact += impactClub;
        });

        // Répartir la population de la ville
        const populationPondéréeUser = Math.round(
            city.populationCalculee * (impactUser / totalImpact)
        );

        const cityResult = {
            ...city,
            impactUser: impactUser,
            totalImpact: totalImpact,
            populationPonderee: populationPondéréeUser,
        };

        results.cities.push(cityResult);
        results.totalPopulationUser += populationPondéréeUser;
    });

    return results;
};

const getCatchmentAreaData = async (
    communesPlusAdjacentDepartmentsData,
    catchmentAreaData,
    padelsData
) => {
    let catchementAreaResult = {
        totalPopulation: 0,
        cities: [],
        padels: [],
    };

    let catchmentAreaZone;
    let padelSearchingZone;

    if (catchmentAreaData.features.length === 2) {
        catchmentAreaZone = catchmentAreaData.features[1];
        padelSearchingZone = catchmentAreaData.features[0];
    } else if (catchmentAreaData.features.length === 1) {
        catchmentAreaZone = catchmentAreaData.features[0];
        padelSearchingZone = catchmentAreaData.features[0];
    }

    for (const commune of communesPlusAdjacentDepartmentsData.features) {
        // Calcule l'intersection entre la commune et la zone d'isochrone
        const intersection = turf.intersect(commune, catchmentAreaZone);

        if (intersection) {
            const populationCommune = await requestPopulationForCity(
                commune
            ).then((data) => {
                return data;
            });

            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = turf.area(commune);
            const surfaceIntersection = turf.area(intersection);

            if (catchmentAreaZone === padelSearchingZone) {
                if (populationCommune >= 100000) {
                    // Calcule la population proportionnelle dans la zone
                    const populationProportionnelle =
                        populationCommune *
                        (surfaceIntersection / surfaceCommune);

                    catchementAreaResult.cities.push({
                        commune,
                        populationCommune,
                        populationCalculee: Math.round(
                            populationProportionnelle
                        ),
                        calculateProportianalPopulation: true,
                        surfaceCommune,
                        surfaceIntersection,
                    });

                    // Ajoute à la population totale
                    catchementAreaResult.totalPopulation += Math.round(
                        populationProportionnelle
                    );
                } else {
                    catchementAreaResult.cities.push({
                        commune,
                        populationCommune,
                        populationCalculee: populationCommune,
                        calculateProportianalPopulation: false,
                        surfaceCommune,
                        surfaceIntersection,
                    });

                    // Ajoute à la population totale
                    catchementAreaResult.totalPopulation += populationCommune;
                }
            } else {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    populationCommune * (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune,
                    populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            }
        }
    }

    // Recherche des clubs de padels dans la zone
    const padelsInsideZone = turf.pointsWithinPolygon(
        padelsData,
        padelSearchingZone
    );

    const existingPadels = padelsInsideZone?.features?.filter(
        (padel) => padel.properties.existing
    );

    const existingPadelsWithIndoor = {
        type: "FeatureCollection",
        features: existingPadels?.filter(
            (padel) => padel.properties.indoor > 0
        ),
    };

    if (existingPadelsWithIndoor?.features?.length > 0) {
        catchementAreaResult.padels.push(existingPadelsWithIndoor);
    }

    return catchementAreaResult;
};

const CatchmentAreaPopup = ({
    travelTime,
    travelDistance,
    requestMode,
    nbTerrains,
    setRequestMode,
    handleChange,
    onClose,
    calculatePopulationInCatchmentArea,
    citiesInCatchementArea,
    setIsCatchmentAreaResultPopupActive,
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        calculatePopulationInCatchmentArea();
    };

    return (
        <div className="catchment-area-popup-container">
            <FontAwesomeIcon
                icon="fa-solid fa-close"
                id="catchment-area-popup-close"
                onClick={onClose}
            />
            <div className="catchment-area-popup-header">
                <img
                    src={catchmentAreaIcon}
                    alt="Icone de zone de chalandise"
                    height="24px"
                />
                <h3>Zone de chalandise</h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Cliquez sur la carte pour définir le point de départ
                    </span>
                </div>
                <div className="catchment-area-popup-content">
                    <button
                        type="button"
                        className={requestMode === "temps" ? "active" : ""}
                        onClick={() => setRequestMode("temps")}
                    >
                        Temps
                    </button>
                    <button
                        type="button"
                        className={requestMode === "distance" ? "active" : ""}
                        onClick={() => setRequestMode("distance")}
                    >
                        Distance
                    </button>
                </div>
                {requestMode === "temps" && (
                    <div className="catchment-area-popup-content">
                        <span className="catchment-area-popup-label">
                            Temps de trajet :
                        </span>
                        <span className="catchment-area-popup-value">
                            <input
                                name="travelTime"
                                type="number"
                                min={0}
                                max={30}
                                value={travelTime}
                                onChange={handleChange}
                            />{" "}
                            minutes
                        </span>
                    </div>
                )}
                {requestMode === "distance" && (
                    <div className="catchment-area-popup-content">
                        <span className="catchment-area-popup-label">
                            Distance :
                        </span>
                        <span className="catchment-area-popup-value">
                            <input
                                name="travelDistance"
                                type="number"
                                min={0}
                                max={25}
                                value={travelDistance}
                                onChange={handleChange}
                            />{" "}
                            km
                        </span>
                    </div>
                )}
                <div className="catchment-area-popup-content">
                    <span className="catchment-area-popup-label">
                        Nombre de terrains :
                    </span>
                    <span className="catchment-area-popup-value">
                        <input
                            name="nbTerrains"
                            type="number"
                            min={0}
                            max={20}
                            value={nbTerrains}
                            onChange={handleChange}
                        />{" "}
                        terrains
                    </span>
                </div>
                <div className="catchment-area-popup-footer">
                    <button type="submit">Valider</button>
                    {citiesInCatchementArea.length > 0 && (
                        <button
                            type="button"
                            onClick={() =>
                                setIsCatchmentAreaResultPopupActive(true)
                            }
                        >
                            Voir résultats
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

const CatchmentAreaResultPopup = ({
    totalPopulationInCatchmentArea,
    userTotalPopulationInCatchmentArea,
    citiesInCatchementArea,
    nbPadelsInZone,
    calculateUserPopulation,
    onClose,
    isCatchmentAreaResultPopupLoading,
    setCitiesInCatchementArea,
    recalculatePopulationInCatchmentArea,
    recalculatePopulationAvailable,
    setRecalculatePopulationAvailable,
    canSetInitialCities,
    setCanSetInitialCities,
}) => {
    const [citiesInCatchementAreaOrdered, setCitiesInCatchementAreaOrdered] =
        useState([]);

    const [
        initialCitiesInCatchementAreaOrdered,
        setInitialCitiesInCatchementAreaOrdered,
    ] = useState([]);

    const [
        allCitiesProportionalPopulation,
        setAllCitiesProportionalPopulation,
    ] = useState({ use: false, value: false });

    useEffect(() => {
        const citiesOrdered = citiesInCatchementArea.slice().sort((a, b) => {
            return b.populationCalculee - a.populationCalculee;
        });
        setCitiesInCatchementAreaOrdered(citiesOrdered);
        if (canSetInitialCities && citiesInCatchementArea.length > 0) {
            setInitialCitiesInCatchementAreaOrdered(citiesOrdered);
            setCanSetInitialCities(false);
        }
    }, [citiesInCatchementArea]);

    // useEffect(() => {
    //     if (calculateUserPopulation) {
    //         setAllCitiesProportionalPopulation({ use: true, value: true });
    //     } else {
    //         setAllCitiesProportionalPopulation({ use: false, value: false });
    //     }
    // }, [calculateUserPopulation]);

    const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.every(
            (city, index) =>
                city.calculateProportianalPopulation ===
                arr2[index].calculateProportianalPopulation
        );
    };

    useEffect(() => {
        if (
            areArraysEqual(
                citiesInCatchementAreaOrdered,
                initialCitiesInCatchementAreaOrdered
            )
        ) {
            setRecalculatePopulationAvailable(false);
        } else {
            setRecalculatePopulationAvailable(true);
        }
    }, [citiesInCatchementAreaOrdered]);

    useEffect(() => {
        if (allCitiesProportionalPopulation.use) {
            setCitiesInCatchementArea((prevCities) => {
                return prevCities.map((city) => {
                    return {
                        ...city,
                        calculateProportianalPopulation:
                            allCitiesProportionalPopulation.value
                                ? true
                                : false,
                    };
                });
            });
        }
    }, [allCitiesProportionalPopulation]);

    const changePopulationCalculation = (cityId, changeAllCities = false) => {
        if (changeAllCities) {
            setAllCitiesProportionalPopulation({
                use: true,
                value: !allCitiesProportionalPopulation.value,
            });
        } else {
            setAllCitiesProportionalPopulation({
                use: false,
                value: false,
            });

            setCitiesInCatchementArea((prevCities) => {
                return prevCities.map((city) => {
                    if (city.commune.properties.id === cityId) {
                        return {
                            ...city,
                            calculateProportianalPopulation:
                                !city.calculateProportianalPopulation,
                        };
                    }
                    return city;
                });
            });
        }
    };

    return (
        <div className="catchment-area-result-popup-container">
            <FontAwesomeIcon
                icon="fa-solid fa-close"
                id="catchment-area-result-popup-close"
                onClick={onClose}
            />
            {!isCatchmentAreaResultPopupLoading ? (
                <div className="catchment-area-result-popup-wrapper">
                    <div className="catchment-area-result-popup-header">
                        <h3>
                            Résultats de la zone de chalandise{" "}
                            <span>
                                {calculateUserPopulation ? "nette" : "brute"}
                            </span>
                        </h3>
                    </div>
                    {calculateUserPopulation ? (
                        <>
                            <div className="catchment-area-result-popup-flex">
                                <div className="catchment-area-result-popup-content">
                                    <span className="catchment-area-result-popup-label">
                                        Population brute dans la zone:
                                    </span>
                                    <span className="catchment-area-result-popup-value population-totale">
                                        {" " +
                                            totalPopulationInCatchmentArea.toLocaleString(
                                                "fr-FR"
                                            ) +
                                            " habitants"}
                                    </span>
                                </div>
                                <div className="catchment-area-result-popup-content">
                                    <span className="catchment-area-result-popup-label">
                                        Population nette dans la zone:
                                    </span>
                                    <span className="catchment-area-result-popup-value population-totale">
                                        {" " +
                                            userTotalPopulationInCatchmentArea.toLocaleString(
                                                "fr-FR"
                                            ) +
                                            " habitants"}
                                    </span>
                                </div>
                            </div>
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Padels concurrents dans la zone:
                                </span>
                                <span className="catchment-area-result-popup-value population-totale">
                                    {" " + nbPadelsInZone}
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="catchment-area-result-popup-content">
                                <span className="catchment-area-result-popup-label">
                                    Population brute dans la zone:
                                </span>
                                <span className="catchment-area-result-popup-value population-totale">
                                    {" " +
                                        totalPopulationInCatchmentArea.toLocaleString(
                                            "fr-FR"
                                        ) +
                                        " habitants"}
                                </span>
                            </div>
                        </>
                    )}

                    <div className="catchment-area-result-popup-content cities-detail">
                        <span className="catchment-area-result-popup-label">
                            Détails des villes présentes dans la zone:
                        </span>
                    </div>

                    {calculateUserPopulation ? (
                        <>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Population proportionnelle</th>
                                            <th>Intersection</th>
                                            <th>Population pondérée</th>
                                            <th>Votre part de population</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {citiesInCatchementAreaOrdered.map(
                                            (city) => {
                                                const pourcentageIntersection =
                                                    Math.round(
                                                        (city.surfaceIntersection /
                                                            city.surfaceCommune) *
                                                            100
                                                    );

                                                return (
                                                    <tr
                                                        key={
                                                            city.commune
                                                                .properties.id
                                                        }
                                                    >
                                                        <th>
                                                            {
                                                                city.commune
                                                                    .properties
                                                                    .nom
                                                            }
                                                        </th>
                                                        <td>
                                                            {city.populationCalculee.toLocaleString(
                                                                "fr-FR"
                                                            )}
                                                        </td>
                                                        <td
                                                            className={
                                                                pourcentageIntersection <=
                                                                25
                                                                    ? "low-intersection-percentage"
                                                                    : ""
                                                            }
                                                        >
                                                            {pourcentageIntersection +
                                                                " %"}
                                                        </td>
                                                        <td>
                                                            {city.populationPonderee?.toLocaleString(
                                                                "fr-FR"
                                                            )}
                                                        </td>
                                                        <td>
                                                            {Math.round(
                                                                (city.impactUser /
                                                                    city.totalImpact) *
                                                                    100
                                                            ) + " %"}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Population totale</th>
                                            <th>Population proportionnelle</th>
                                            <th>Intersection</th>
                                            <th>
                                                Calcul proportionnel{" "}
                                                <div
                                                    className={
                                                        "proportional-toggle" +
                                                        (allCitiesProportionalPopulation.value
                                                            ? " active"
                                                            : "")
                                                    }
                                                >
                                                    <div
                                                        className="toggle-outer"
                                                        onClick={() =>
                                                            changePopulationCalculation(
                                                                -1,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <div className="toggle-inner" />
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {citiesInCatchementAreaOrdered.map(
                                            (city) => {
                                                const pourcentageIntersection =
                                                    Math.round(
                                                        (city.surfaceIntersection /
                                                            city.surfaceCommune) *
                                                            100
                                                    );

                                                return (
                                                    <tr
                                                        key={
                                                            city.commune
                                                                .properties.id
                                                        }
                                                    >
                                                        <th>
                                                            {
                                                                city.commune
                                                                    .properties
                                                                    .nom
                                                            }
                                                        </th>
                                                        <td>
                                                            {city.populationCommune.toLocaleString(
                                                                "fr-FR"
                                                            )}
                                                        </td>
                                                        {city.calculateProportianalPopulation ? (
                                                            <td>
                                                                {city.populationCalculee.toLocaleString(
                                                                    "fr-FR"
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <td></td>
                                                        )}
                                                        <td
                                                            className={
                                                                pourcentageIntersection <=
                                                                25
                                                                    ? "low-intersection-percentage"
                                                                    : ""
                                                            }
                                                        >
                                                            {pourcentageIntersection +
                                                                " %"}
                                                        </td>
                                                        <td
                                                            className={
                                                                "proportional-toggle" +
                                                                (city.calculateProportianalPopulation
                                                                    ? " active"
                                                                    : "")
                                                            }
                                                        >
                                                            <div
                                                                className="toggle-outer"
                                                                onClick={() =>
                                                                    changePopulationCalculation(
                                                                        city
                                                                            .commune
                                                                            .properties
                                                                            .id
                                                                    )
                                                                }
                                                            >
                                                                <div className="toggle-inner" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="recalculate-container">
                                <button
                                    className={
                                        "recalculate" +
                                        (recalculatePopulationAvailable
                                            ? " available"
                                            : "")
                                    }
                                    onClick={
                                        recalculatePopulationInCatchmentArea
                                    }
                                >
                                    Recalculer les populations
                                </button>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};

function CatchmentArea({ onActiveChange, padelsData }) {
    const [hideCollectionTab, setHideCollectionTab] =
        useGlobalState("hideCollectionTab");

    const { current: map } = useMap();

    const [
        communesPlusAdjacentDepartmentsData,
        setCommunesPlusAdjacentDepartmentsData,
    ] = useState([]);

    const [isCatchmentAreaPopupActive, setIsCatchmentAreaPopupActive] =
        useState(false);
    const [
        isCatchmentAreaResultPopupActive,
        setIsCatchmentAreaResultPopupActive,
    ] = useState(false);

    const [marker, setMarker] = useState(null);
    const [travelTime, setTravelTime] = useState(0);
    const [travelDistance, setTravelDistance] = useState(0);
    const [requestMode, setRequestMode] = useState("temps");
    const [nbTerrains, setNbTerrains] = useState(0);
    const [nbPadelsInZone, setNbPadelsInZone] = useState(0);
    const [calculateUserPopulation, setCalculateUserPopulation] =
        useState(false);

    const [totalPopulationInCatchmentArea, setTotalPopulationInCatchmentArea] =
        useState(0);
    const [
        userTotalPopulationInCatchmentArea,
        setUserTotalPopulationInCatchmentArea,
    ] = useState(0);
    const [citiesInCatchementArea, setCitiesInCatchementArea] = useState([]);
    const [
        isCatchmentAreaResultPopupLoading,
        setIsCatchmentAreaResultPopupLoading,
    ] = useState(false);
    const [recalculatePopulationAvailable, setRecalculatePopulationAvailable] =
        useState(false);
    const [canSetInitialCities, setCanSetInitialCities] = useState(true);

    useEffect(() => {
        map.on("click", handleMapClick);

        return () => {
            map.off("click", handleMapClick);
        };
    });

    const concatenateGeoJSONArrays = (geoJSONArrays) => {
        // Vérifier si geoJSONArrays est valide
        if (!Array.isArray(geoJSONArrays) || geoJSONArrays.length === 0) {
            console.error(
                "geoJSONArrays est vide ou invalide : ",
                geoJSONArrays
            );
            return;
        }

        // Utiliser reduce pour concaténer toutes les features
        const allCities = geoJSONArrays.reduce((acc, geoJSON) => {
            if (geoJSON.type !== "FeatureCollection") {
                console.error(
                    "Un objet GeoJSON n'est pas de type FeatureCollection : ",
                    geoJSON
                );
                return acc;
            }

            if (geoJSON.features && Array.isArray(geoJSON.features)) {
                return acc.concat(geoJSON.features);
            } else {
                console.warn(
                    "Pas de features valides pour cet objet GeoJSON : ",
                    geoJSON
                );
                return acc;
            }
        }, []);

        // Créer un nouvel objet GeoJSON avec les données concaténées
        const newArray = {
            type: "FeatureCollection",
            features: allCities, // Contient toutes les villes concaténées
        };

        return newArray;
    };

    useEffect(() => {
        const getCitiesFromMarker = async () => {
            if (marker) {
                const geoJSONArrays = await getCitiesInNearDepartments(marker);
                const newArray = concatenateGeoJSONArrays(geoJSONArrays);
                setCommunesPlusAdjacentDepartmentsData(newArray);
            }
        };
        getCitiesFromMarker();
    }, [marker]);

    const handleChange = (e) => {
        switch (e.target.name) {
            case "travelTime":
                if (e.target.value < 0) {
                    setTravelTime(0);
                } else if (e.target.value > 30) {
                    setTravelTime(30);
                } else {
                    setTravelTime(e.target.value);
                }
                break;
            case "travelDistance":
                if (e.target.value < 0) {
                    setTravelDistance(0);
                } else if (e.target.value > 25) {
                    setTravelDistance(25);
                } else {
                    setTravelDistance(e.target.value);
                }
                break;
            case "nbTerrains":
                if (e.target.value < 0) {
                    setNbTerrains(0);
                } else if (e.target.value > 20) {
                    setNbTerrains(20);
                } else {
                    setNbTerrains(e.target.value);
                }
                break;
            default:
                break;
        }
    };

    const handleMapClick = (e) => {
        if (isCatchmentAreaPopupActive) {
            if (marker) {
                removeMarker();
            }
            createMarker(e.lngLat);
        }
    };

    const resetCatchmentArea = () => {
        onActiveChange(false);
        removeMarker();
        setIsCatchmentAreaPopupActive(false);
        setIsCatchmentAreaResultPopupActive(false);
        setHideCollectionTab(false);
        setTravelTime(0);
        setTravelDistance(0);
        setRequestMode("temps");
        setNbTerrains(0);
        setNbPadelsInZone(0);
        setCalculateUserPopulation(false);
        setTotalPopulationInCatchmentArea(0);
        setUserTotalPopulationInCatchmentArea(0);
        setCitiesInCatchementArea([]);
        setIsCatchmentAreaResultPopupLoading(false);
        setRecalculatePopulationAvailable(false);
        setCanSetInitialCities(true);
    };

    const handleClick = (e) => {
        if (e.target.id === "catchment-area-icon") {
            if (!isCatchmentAreaPopupActive) {
                setHideCollectionTab(true);
                setIsCatchmentAreaPopupActive(true);
                onActiveChange(true);
            } else {
                resetCatchmentArea();
            }
        }
    };

    const handleCatchmentAreaPopupClose = () => {
        resetCatchmentArea();
    };

    const handleCatchmentAreaResultPopupClose = () => {
        setIsCatchmentAreaResultPopupActive(false);
    };

    const createMarker = (location) => {
        let markerElt = document.createElement("div");
        markerElt.className = "catchment-area-marker";
        markerElt.id = "catchment-area-marker";
        markerElt.innerText = "i";

        const marker = new mapboxgl.Marker({
            element: markerElt,
            draggable: false,
        });
        marker.setLngLat([location.lng, location.lat]);
        marker.addTo(map.getMap());
        setMarker(marker);
    };

    const removeMarker = () => {
        marker?.remove();
        setMarker(null);
    };

    const calculatePopulationInCatchmentArea = async () => {
        nbTerrains > 0
            ? setCalculateUserPopulation(true)
            : setCalculateUserPopulation(false);

        setIsCatchmentAreaResultPopupLoading(true);
        setIsCatchmentAreaResultPopupActive(true);

        if (marker) {
            requestIsochrone(
                marker._lngLat.lng,
                marker._lngLat.lat,
                travelTime,
                travelDistance,
                requestMode,
                nbTerrains
            ).then(async (data) => {
                map.getSource("catchmentArea").setData(data);

                const catchmentAreaResult = await getCatchmentAreaData(
                    communesPlusAdjacentDepartmentsData,
                    data,
                    padelsData
                );

                setNbPadelsInZone(
                    catchmentAreaResult.padels?.[0].features?.length
                );

                const weightedPopulationInZone = calculateWeightedPopulation(
                    marker._lngLat,
                    nbTerrains,
                    catchmentAreaResult.cities,
                    catchmentAreaResult.padels
                );

                setTotalPopulationInCatchmentArea(
                    catchmentAreaResult.totalPopulation
                );
                setUserTotalPopulationInCatchmentArea(
                    weightedPopulationInZone.totalPopulationUser
                );

                setCitiesInCatchementArea(weightedPopulationInZone.cities);
                setIsCatchmentAreaResultPopupLoading(false);
            });
        } else {
            console.log("No marker");
            setIsCatchmentAreaResultPopupLoading(false);
        }
    };

    const recalculatePopulationInCatchmentArea = () => {
        setIsCatchmentAreaResultPopupLoading(true);
        setIsCatchmentAreaResultPopupActive(true);
        setRecalculatePopulationAvailable(false);
        setCanSetInitialCities(true);

        let catchementAreaResult = {
            totalPopulation: 0,
            cities: [],
        };

        for (const city of citiesInCatchementArea) {
            // Calcule les surfaces (en km² par exemple)
            const surfaceCommune = city.surfaceCommune;
            const surfaceIntersection = city.surfaceIntersection;

            if (city.calculateProportianalPopulation) {
                // Calcule la population proportionnelle dans la zone
                const populationProportionnelle =
                    city.populationCommune *
                    (surfaceIntersection / surfaceCommune);

                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: Math.round(populationProportionnelle),
                    calculateProportianalPopulation: true,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += Math.round(
                    populationProportionnelle
                );
            } else {
                catchementAreaResult.cities.push({
                    commune: city.commune,
                    populationCommune: city.populationCommune,
                    populationCalculee: city.populationCommune,
                    calculateProportianalPopulation: false,
                    surfaceCommune,
                    surfaceIntersection,
                });

                // Ajoute à la population totale
                catchementAreaResult.totalPopulation += city.populationCommune;
            }
        }

        setTotalPopulationInCatchmentArea(catchementAreaResult.totalPopulation);
        setCitiesInCatchementArea(catchementAreaResult.cities);
        setIsCatchmentAreaResultPopupLoading(false);
    };

    return (
        <div
            id="catchment-area-button"
            className={
                "catchment-area-button " +
                (isCatchmentAreaPopupActive && "active")
            }
            onClick={handleClick}
        >
            <img
                id="catchment-area-icon"
                src={catchmentAreaIcon}
                alt=""
                height="32px"
            />
            {isCatchmentAreaPopupActive && (
                <CatchmentAreaPopup
                    travelTime={travelTime}
                    travelDistance={travelDistance}
                    requestMode={requestMode}
                    nbTerrains={nbTerrains}
                    setRequestMode={setRequestMode}
                    handleChange={handleChange}
                    onClose={handleCatchmentAreaPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    setIsCatchmentAreaResultPopupActive={
                        setIsCatchmentAreaResultPopupActive
                    }
                />
            )}
            {isCatchmentAreaResultPopupActive && (
                <CatchmentAreaResultPopup
                    totalPopulationInCatchmentArea={
                        totalPopulationInCatchmentArea
                    }
                    userTotalPopulationInCatchmentArea={
                        userTotalPopulationInCatchmentArea
                    }
                    citiesInCatchementArea={citiesInCatchementArea}
                    nbPadelsInZone={nbPadelsInZone}
                    calculateUserPopulation={calculateUserPopulation}
                    onClose={handleCatchmentAreaResultPopupClose}
                    calculatePopulationInCatchmentArea={
                        calculatePopulationInCatchmentArea
                    }
                    isCatchmentAreaResultPopupLoading={
                        isCatchmentAreaResultPopupLoading
                    }
                    setCitiesInCatchementArea={setCitiesInCatchementArea}
                    recalculatePopulationInCatchmentArea={
                        recalculatePopulationInCatchmentArea
                    }
                    recalculatePopulationAvailable={
                        recalculatePopulationAvailable
                    }
                    setRecalculatePopulationAvailable={
                        setRecalculatePopulationAvailable
                    }
                    canSetInitialCities={canSetInitialCities}
                    setCanSetInitialCities={setCanSetInitialCities}
                />
            )}
        </div>
    );
}

export default CatchmentArea;
