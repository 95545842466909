const layerStyle = {

    presPctLabel: {
        id: 'presPctLabel',
        type: 'symbol',
        paint: {
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 1
        },
        layout: {
            'text-field': ["get", "libelle"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                13, 8,
                20, 14
            ],
        }
    },

    presLinLine: {
        id: 'presLinLine',
        type: 'line',
        paint: {
            'line-color': '#6FC',
            'line-width': 6
        }
    },

    presLinLabel: {
        id: 'presLinLabel',
        type: 'symbol',
        paint: {
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 1
        },
        layout: {
            'text-field': ["get", "libelle"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                13, 8,
                20, 14
            ],
        }
    },

    presSurfLine: {
        id: 'presSurfLine',
        type: 'line',
        paint: {
            'line-color': '#68C',
            'line-width': 2
        }
    },

    presSurfFill: {
        id: 'presSurfFill',
        type: 'fill',
        paint: {
            'fill-color': '#68C',
            'fill-opacity': 0.3
        }
    },

    presSurfLabel: {
        id: 'presSurfLabel',
        type: 'symbol',
        paint: {
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 1
        },
        layout: {
            'text-field': ["get", "libelle"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                13, 8,
                20, 14
            ],
        }
    },

    PPRLine: {
        id: 'PPRLine',
        type: 'line',
        paint: {
            'line-color': '#F52',
            'line-width': 2
        }
    },

    PPRLabel: {
        id: 'PPRLabel',
        type: 'symbol',
        paint: {
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 1
        },
        layout: {
            'text-field': ["get", "libelle_risque"],
            'text-size': 16,
        }
    },


    currentCollectionParcellesLine: {
        id: 'currentCollectionParcellesLine',
        type: 'line',
        paint: {
            'line-color': '#08C',
            'line-width': 3
        }
    },

    currentCollectionParcellesFill: {
        id: 'currentCollectionParcellesFill',
        type: 'fill',
        paint: {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#ffb338',
                '#08C'
            ],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.6,
                0.5
            ]
        }

    },

    searchCollectionParcellesLine: {
        id: 'searchCollectionParcellesLine',
        type: 'line',
        paint: {
            'line-color': '#08C',
            'line-width': 2,
            'line-opacity': 0.5
        }
    },

    searchCollectionParcellesFill: {
        id: 'searchCollectionParcellesFill',
        type: 'fill',
        paint: {
            'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                '#ffb338',
                '#08C'
            ],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.6,
                0.3
            ]
        }
    },

    keepCollectionParcellesLine: {
        id: 'keepCollectionParcellesLine',
        type: 'line',
        paint: {
            'line-color': '#ffb338',
            'line-width': 3
        }
    },

    keepCollectionParcellesFill: {
        id: 'keepCollectionParcellesFill',
        type: 'fill',
        paint: {
            'fill-color': '#ffb338',
            'fill-opacity': 0.5
        }
    },

    departements: {
        id: 'departements',
        type: 'line',
        paint: {
            'line-color': '#000',
            // change line width according to zoomLevel
            'line-width': 1.5
        }
    },

    communes: {
        id: 'communes',
        type: 'line',
        paint: {
            'line-color': '#000',
            'line-width': 2
        }
    },

    sectionsLine: {
        id: 'sections-line',
        type: 'line',
        paint: {
            'line-color': '#000',
            'line-width': 3,
            'line-dasharray': [2, 2]
        }
    },

    sectionsFill: {
        id: 'sections-fill',
        type: 'fill',
        paint: {
            'fill-color': '#000',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.25,
                0
            ]
        }
    },

    sectionsLabel: {
        id: 'sections-label',
        type: 'symbol',
        layout: {
            'text-field': ["get", "section"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                13, 16,
                15, 20
            ],
        },
        paint: {
            'text-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                "#000",
                "#FFF"
            ],
            'text-halo-width': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                2,
                1
            ],
            'text-halo-color': [
                'case',
                ['boolean', ['feature-state', 'selected'], false],
                "#ffb338",
                "#000"
            ]
        }
    },

    communesLabel: {
        id: 'communes-label',
        type: 'symbol',
        layout: {
            'text-field': ["get", "nom"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                10, 12,
                13, 16
            ],
        },
        paint: {
            'text-color': '#FFF',
            'text-halo-width': 1,
            'text-halo-color': '#000',
        }
    },

    parcellesLabel: {
        id: 'parcelles-label',
        type: 'symbol',
        layout: {
            'text-field': ["get", "numero"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                16.5, 16,
                20, 24
            ],
        },
        paint: {
            'text-color': '#000',
            'text-halo-width': 1,
            'text-halo-color': '#FFF',
        }
    },

    parcellesLine: {
        id: 'parcelles-line',
        type: 'line',
        paint: {
            'line-color': '#FFF',
            'line-width': 1,
            'line-opacity': [
                'interpolate',
                ['linear'], ['zoom'],
                14, 0,
                16, 1,
            ]
        }
    },

    parcellesFill: {
        id: 'parcelles-fill',
        type: 'fill',
        paint: {
            'fill-color': '#08C',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.3,
                0
            ]
        }
    },

    borderLine: {
        id: 'borderLine',
        type: 'line',
        paint: {
            'line-color': '#08C',
            'line-width': 2
        }
    },

    borderFill: {
        id: 'borderFill',
        type: 'fill',
        paint: {
            'fill-color': '#08C',
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.3,
                0
            ]
        }
    },

    batiLine: {
        id: 'batiLine',
        type: 'line',
        paint: {
            'line-color': '#000',
            'line-width': 1,
            'line-opacity': 0.6
        }
    },

    pluLine: {
        id: 'pluLine',
        type: 'line',
        paint: {
            'line-color': [
                "case",
                ["==", ["get", "typezone"], "A"],
                "yellow",
                ["==", ["get", "typezone"], "AUc"],
                "#900",
                ["==", ["get", "typezone"], "N"],
                "green",
                ["==", ["get", "typezone"], "U"],
                "red",
                "black"
            ],
            'line-width': 2,
            'line-opacity': 1
        }
    },

    pluLabel: {
        id: 'pluLabel',
        type: 'symbol',
        layout: {
            'text-field': ["get", "libelle"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                16.5, 24,
                20, 32
            ],
        },
        paint: {
            'text-color': '#FFF',
            'text-halo-color': '#000',
            'text-halo-width': 1
        }
    },

    pluFill: {
        id: 'pluFill',
        type: 'fill',
        paint: {
            "fill-color": [
                "case",
                ["==", ["get", "typezone"], "A"],
                "yellow",
                ["==", ["get", "typezone"], "AUc"],
                "#900",
                ["==", ["get", "typezone"], "AUs"],
                "#900",
                ["==", ["get", "typezone"], "N"],
                "green",
                ["==", ["get", "typezone"], "U"],
                "red",
                "black"
            ],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.3,
                0.1
            ]
        }
    },

    monHistSymbol: {
        id: 'monHistSymbol',
        type: 'symbol',
        paint: {
            'text-color': '#FFB500',
            'text-halo-color': '#000',
            'text-halo-width': 1
        },
        layout: {
            'text-field': ["get", "appellation_courante"],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                13, 10,
                20, 20
            ],
            'text-offset': [0, 3],
            'icon-image': 'monument',
            'icon-size': ['interpolate',
                ['linear'], ['zoom'],
                9, 0.001,
                10, 0.05,
                20, 0.1
            ],
        }
    },

    // padelsSymbol: {
    //     id: 'padelsSymbol',
    //     type: 'symbol',
    //     paint: {
    //         'text-color': 'rgb(0, 136, 204)',
    //         'text-halo-color': '#000',
    //         'text-halo-width': 1
    //     },
    //     layout: {
    //         'text-field': ["get", "name"],
    //         'text-size': ['interpolate',
    //             ['linear'], ['zoom'],
    //             13, 10,
    //             20, 15
    //         ],
    //         'text-offset': [0, 3],
    //         'icon-image': 'padel',
    //         'icon-size': ['interpolate', ['linear'], ['zoom'],
    //             8, 0.03,
    //             20, 0.1
    //         ],
    //     }
    // },

    padelsSymbol: {
        id: 'padelsSymbol',
        type: 'circle',
        paint: { 
            // 'circle-color': 'rgb(0, 136, 204)',
            'circle-color': ['case',
                ["==", ["get", "existing"], true],
                "rgb(0, 136, 204)",
                ["==", ["get", "existing"], false],
                "rgb(204, 173, 0)",
                "rgb(0, 136, 204)"
            ],
            'circle-radius': ['interpolate',
                ['linear'], ['zoom'],
                5, 5,
                10, 8
            ],
            'circle-stroke-width' : 1
        }
    },

    rulerLine: {
        id: "ruler-line-layer",
        type: "line",
        source: "ruler-line",
        layout: {
            "line-cap": "round",
        },
        paint: {
            "line-color": "black",
            "line-width": 2,
        },
        minzoom: 0
    },

    rulerLineFill: {
        id: "ruler-line-layer-fill",
        type: "fill",
        source: "ruler-line",
        layout: {},
        paint: {
            "fill-color": "#0088cc",
            "fill-opacity": 0.2
        },
    },

    rulerLineDashray: {
        id: "ruler-line-layer-dashray",
        type: "line",
        source: "ruler-line",
        paint: {
            "line-color": "black",
            "line-width": 5,
            "line-dasharray": [0.2, 3]
        },
    },

    // PEB
    pebLine: {
        id: 'pebLine',
        type: 'line',
        paint: {
            'line-color': ['case',
                ["==", ["get", "ZONE"], "A"],
                "red",
                ["==", ["get", "ZONE"], "B"],
                "orange",
                ["==", ["get", "ZONE"], "C"],
                "green",
                ["==", ["get", "ZONE"], "D"],
                "blue",
                "black"
            ],
            'line-width': 3,
            'line-opacity': 1
        }
    },

    pebFill: {
        id: 'pebFill',
        type: 'fill',
        paint: {
            'fill-color': ['case',
                ["==", ["get", "ZONE"], "A"],
                "red",
                ["==", ["get", "ZONE"], "B"],
                "orange",
                ["==", ["get", "ZONE"], "C"],
                "green",
                ["==", ["get", "ZONE"], "D"],
                "blue",
                "black"
            ],
            'fill-opacity': 0.25
        }
    },

    pebLabel: {
        id: 'pebLabel',
        type: 'symbol',
        layout: {
            'text-field': ["concat", "Zone PEB ",
                ["get", "ZONE"]],
            'text-size': ['interpolate',
                ['linear'], ['zoom'],
                16.5, 24,
                20, 32
            ],
        },
        paint: {
            'text-color': ['case',
                ["==", ["get", "ZONE"], "A"],
                "red",
                ["==", ["get", "ZONE"], "B"],
                "orange",
                ["==", ["get", "ZONE"], "C"],
                "green",
                ["==", ["get", "ZONE"], "D"],
                "blue",
                "black"
            ],
            'text-halo-color': '#FFF',
            'text-halo-width': 1
        },
        minzoom: 12
    },

}

export default layerStyle;